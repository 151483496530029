
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the popup is on top of other content */
  }
  
  .popup-content {
    background-color: #fff;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    text-align: center;
  }
  
  .close-button {
    background: #ef2853;
    color: #fff;
    position: absolute;
    top: -35px;
    right: -20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    opacity: 1;
    text-align: center;
    line-height: 2;
    font-size: 20px;
    transition: all ease-in-out .3s;
  }

  .modal__header {
    background-color: #000d6b;
    margin: 0;
    color: #fff;
    padding: 30px 15px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}

.modal__body{
    position: relative;
}

.modal__body p{
    font-size: 22px;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    line-height: 30px;
    margin-top: 15px;
}

.modal__body p.phone i {
    color: #000d6b;
    text-decoration: none;
    font-weight: 400;
}

.modal__body p.phone a {
    color: #000d6b;
    text-decoration: none;
    font-weight: 400;
}
  
  .popup__phone{
    border: 1px black solid;
    border-radius: 0.5rem;
    background: #000d6b;
  }

  .phone{
    font-size: 2rem !important
  }

  .modal-call-btn{
    background-color: #000d6b;
    border-radius: 35px;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border: 1px solid #000d6b;
  }

  .call-us{
    margin-top: 35px;
  }

  .modal__footer{
    padding: 20px;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }


  @media (max-width: 600px) {
    .popup-content {
      width: 95%; /* Adjusts popup size for smaller screens */
    }
    .phone {
        font-size: 1.5rem !important;
    }
  }
  