.slider__item{
    max-width: 100%; 
    height: 650px;
}

.slider__item-01{
    background: linear-gradient(rgba(17, 13, 13, 0.5), rgba(17, 13, 13, 0.5)),
         url('../assets/all-images/slider-img/Emirates-Flights.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.slider__item-02{
    background: linear-gradient(rgba(17, 13, 13, 0.5), rgba(17, 13, 13, 0.5)),
         url('../assets/all-images/slider-img/Emirates-Flights.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}
.slider__item-03{
    background: linear-gradient(rgba(17, 13, 13, 0.5), rgba(17, 13, 13, 0.5)),
         url('../assets/all-images/slider-img/Emirates-Flights.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero__slider {
    overflow: hidden;
}

.slider__content {
    padding-top: 100px;
    text-align: center;
}

.slider__content h1{
     font-size: 3.2rem;
     font-weight: 600;
}

.reserve__btn{
    background: #fff !important;
    color: #000d6b;
    border: none;
    outline: none;
}

.reserve__btn a{
    text-decoration: none;
    font-weight: 600;
}

@media only screen and (max-width:992px){
    .slider__item{
        height: 530px;
        width: 100vw;
    }
    .slider__content{
        margin-top: -70px;
    }
    .slider__content h1{
        font-size: 2rem;
    }
}

@media only screen and (max-width:768px){
    .slider__item{
        height: 450px;
    }

  

    .slider__content h1 {
        font-size: 1.5rem;
        
    }

}