
/* ==================== google fonts ===================== */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,400;0,600;0,700;1,200;1,900&display=swap');

/* ===================  base Style ====================== */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Source Sans 3, sans-serif;
}


/* ===================   Pre Define CSS  ====================== */
h1,h2,h3,h4,h5,h6{
    color: #000d6b;
    font-weight: 600;
    margin-bottom: 0;
}

h3,h4{
    font-size: 1.8rem;
}

h5,h6{
    font-size: 1.2rem;
}

a{
    text-decoration: none;
    color: unset;
}
a:hover{
    color: unset;
}
ul{
    margin: 0;
    list-style: none;
}

.section__title{
    color: #000d6b;
    font-weight: 600;
    font-size: 2rem;
}

.section__subtitle{
    color: #f9a826;
    font-weight: 600;
    font-size: 1.2rem;
}

.section__description{
    color: #7c8a97;
    font-size: .9rem;
    line-height: 30px;
}
section{
    padding: 50px 0px;
}

.btn{
    padding: 7px 15px;
    background:#000d6b;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    transition: .3s;
}

.btn:hover{
    color: #fff;
    background: #000c6bd0;
}

@media only screen and (max-width:768px){
    section{
        padding: 30pxp 0px;
        padding-bottom: inherit;
    }
    .section__title{
        font-size: 1.6rem;
    }
    .section__subtitle{
        font-size: 1.1rem;
    }

    .mobile__service{
        padding-top: 420px;
    }
    .service__item{
        text-align: center;
        margin-bottom: -10px;
    }
}