/* .find__cars-left{
    background: linear-gradient(rgb(249, 168, 38, 0.9),rgb(249, 168, 38, 0.9)),
        url('../assets/all-images/toyota-offer-2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 65px 35px;
} */

/* .find__cars-left h2{
    font-weight: 600;
    font-size: 2.8rem;
} */

.form__group{
    width: 23%;
    margin-bottom: 0;
}

.form__group input{
    border: 1px solid #7c8a972a;
    padding: 8px 12px;
    width: 100%;
    outline: none;
}

.form__group input::placeholder{
    color: #7c8a97;
}

.form{
    background: #fff;
    padding-top: 18px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.select__group{
    width: 12%;
}

.select__group select{
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #7c8a972a;
    outline: none;
    color: #7c8a97;
    font-size: 0.9rem;
}

.hero__slider-section{
    position: relative;
    z-index: 10;
    /* margin-bottom: 200px; */
}

.hero__form{
    position: absolute;
    bottom: 150px;
    z-index: 11;
    width: 100%;
}

.form__row{
    background:#fff;
    box-shadow: 5px 5px 15px -5px #7c8a97;
}

.journey__time::before{
    content: 'Journeytime';
    margin-right: 0.2;
    color: #7c8a97;
}

.select__group-btn{
    margin-left: auto !important;
}

.find__car-btn{
    background: #000d6b !important;
    color: #fff !important;
    padding: 8px 15px;
    width: 100%;
    border: none;
    outline: none;
    margin-left: auto;
}

.find__car-btn-emirate{
    background: #e32f37 !important;
    color: #fff !important;
    padding: 8px 15px;
    width: 100%;
    border: none;
    outline: none;
    margin-left: auto;
}

.col-lg-1 {
    flex: 0 0 auto;
    width: 12.333333% !important;
}

.label{
    font-size: 0.9rem !important;
}

@media only screen and (min-width: 992px){
    .col-xl-1 {
        flex: 0 0 auto;
        width: 12.333333% !important;
    }
}
@media only screen and (max-width: 992px){
    .col-md-8{
        width: 100% !important;
    }
    .find__cars-left{
        padding: 65px 22px;
    }

    .find__cars-left h2{
        font-size: 1.5rem;
    }

    .form{
        padding: 30px 10px;
    }

    .form__group input,
    .select__group select{
        padding: 7px 10px;
        font-size: 0.8rem;
    }

    .hero__form{
        bottom: -100px;
    }
}

@media only screen and (max-width: 768px){
    .find__cars-left{
        padding-top: 20px;
        padding-bottom: 0;
        background: #fff;
        text-align: center;
    }

    .form{
        padding: 20px 10px;
    }

    .select__group-btn{
        margin-top: 1.5rem;
    }
}

@media only screen and (max-width:576px){
    .form__group{
        width: 100%;
        margin: auto;
        margin-bottom: 15px;
    }

    .form__group input{
        font-size: 0.7rem !important;
    }

    .select__group{
        width: 100%;
        padding-right: 10px;
        font-size: 0.7rem;
    }

    .find__car-btn{
        font-size: 0.8rem;
    }

    .find__cars-left h2{
        font-size: 1rem;
    }

    .hero__form{
        bottom: -450px;
    }
}

