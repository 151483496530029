.header__top{
    padding: 10px 0px;
    background: #000d6b;
    color: #fff;
}
.header__top__left{
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.header__top__left span{
    font-size: 0.9rem;
}

.header__top__help{
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
}
.header__top__help i{
    background: #fff;
    color: #000d6b;
    padding: 2px 4px;
    border-radius: 50%;
    font-size: 0.7rem;
}

.header__top__right a{
    text-decoration: none;
    color: #fff;
    font-size: 0.9rem;
}
.header__top__right a:hover{
    color: #fff;
}

/* ========== header middle style ========== */

.header__middle{
    padding: 10px 0px;
}

.logo h1 a span{
    font-size: 2rem;
}

.logo h1 a i {
    font-size: 2.1rem;
}

.logo h1 a {
    text-decoration: none;
    color: #000d6b;
}

.logo h1{
    line-height: 2.4rem;
}

.header__location span i{
    font-size: 2.2rem;
    color: #000d6b;
}

.header__location-content h4{
    font-size: 1.2rem;
    font-weight: 700;
    color: #000d6b;
}

.header__location-content h6{
    font-weight: 600;
}

.header__btn {
    background-color: #000d6b !important;
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 5px;
}

.header__btn a{
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    text-decoration: none;
    color: #fff;
    /* justify-content: end; */
    font-size: 0.9rem;
}

.header__btn a:hover {
    color: #fff;
}

/* ============= main navbar Style ============= */

.main__navbar{
    padding: 15px 0px;
    background: #000d6b;
}

.mobile__menu i{
    color: #000d6b;
    font-size: 1.3rem;
    cursor: pointer;
}


.menu__logo{
    display: none;
}
.menu__number{
    display: none;
}
.mobile__menu{
    display: none;
}

.menu{
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
}

.nav__item{
    color: #fff;
    text-decoration: none;
    transition: .3s;
    width: max-content;
}

.nav__item:hover{
    color: #f9a826;
}

.search__box{
    border: 1px solid #7c8a972f;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    background: #020a4de9;
}

.search__box input{
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
}

.search__box input::placeholder{
    font-size: 0.8rem;
}

.search__box span i{
    color: #c9c1c1da;
    cursor: pointer;
}

.nav__active{
    color: #f9a826;
}


@media only screen and (max-width: 1200px){

    .navigation{
        margin-left: -2rem;
    }
    .menu{
        display: flex;
        align-items: center;
        column-gap: 1.8rem !important;
    }

    .emirate__logo{
        font-size: 1.8rem !important
    }
}


@media only screen and (max-width: 992px){
    .nav__right {
        display: none;
    }
    .header__world{
        display: none;
    }

    .menu{
        display: flex;
        align-items: center;
        column-gap: 0.7rem !important;
        font-size: 0.9rem;
    }

    .navigation{
        margin-left: -2rem;
    }
    .emirate__logo{
        font-size: 1.8rem !important
    }
}



@media only screen and (max-width:768px) {
    .header__top,
    .header__middle,
    .nav__right {
        display: none;
    }

    .menu__logo,
    .menu__number,
    .mobile__menu{
        display: block;    
    }

    .menu__logo{
        font-size: 0%;
    }

     .menu__number h7{
        font-weight: 700;
    } 
    .navigation{
        background: rgb(0, 13, 107, 0.6);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        display: none;
    }

    .menu{
        width: 250px;
        height: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 1rem;
        justify-content: center;
    }

    .menu a{
        color: #000d6b;
        font-weight: 600;
        font-size: 0.9rem;
    }
    
    .menu__active{
        display: block;
    }

    .main__navbar{
        padding: 15px 0px;
        background:#fff;
    }

    .emirate__logo{
        font-size: 1.2rem;
    }
}

@media only screen and (max-width:576px){

    .header__top,
    .header__middle,
    .nav__right {
        display: none;
    }

    .menu__logo,
    .menu__number,
    .mobile__menu{
        display: block;    
    }

    .menu__logo{
        font-size: 0%;
        height: 2rem;
    }

    .menu__logo img{
        height: auto;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .menu__number{
        height: 0rem;
        white-space: pre;
    }

     /* .menu__number h7{
        font-weight: 700;
    }  */

    .navigation{
        background: rgb(0, 13, 107, 0.6);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        display: none;
    }

    .menu{
        width: 250px;
        height: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 1rem;
        justify-content: center;
    }

    .menu a{
        color: #000d6b;
        font-weight: 600;
        font-size: 0.9rem;
    }
    
    .menu__active{
        display: block;
    }

    .main__navbar{
        padding: 15px 0px;
        background:#fff;
    }

    .emirate__logo{
        font-size: 1rem !important ;
    }

}

